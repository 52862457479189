<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування Вакансія" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Label *"
                  v-model="label"
                  :messages="['The recommended number of characters is 35']"
                  @input="delete errors.label"
                  :error="!!errors.label"
                  :error-messages="errors.label"
                />
                <va-input
                  label="City *"
                  v-model="city"
                  :messages="['The recommended number of characters is 25']"
                  @input="delete errors.city"
                  :error="!!errors.city"
                  :error-messages="errors.city"
                />
                <va-input
                  label="Link *"
                  v-model="link"
                  :messages="['The recommended number of characters is 255']"
                  @input="delete errors.link"
                  :error="!!errors.link"
                  :error-messages="errors.link"
                />
                <va-date-picker
                  label="Published at *"
                  :config="{enableTime: true, locale: {firstDayOfWeek: 1}, dateFormat: 'Y-m-d H:i'}"
                  v-model="published_at"
                  @input="delete errors.published_at"
                  :error="!!errors.published_at"
                  :error-messages="errors.published_at"
                  weekDays
                />
                <va-toggle label="Published" small v-model="published"/>
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      label: '',
      city: '',
      link: '',
      published_at: '2018-05-08 14:10',
      published: false,

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    if (!this.$attrs.id) {
      this.published_at = new Date().toISOString().substr(0, 16).replace('T', ' ')
    }
    this.fetch()
  },
  methods: {
    submit () {
      const data = {
        label: this.label,
        city: this.city,
        link: this.link,
        published_at: this.published_at,
        published: !!this.published,
      }
      if (this.$attrs.id) { // update
        axios.put(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/vacancy/${this.$attrs.id}`, data)
          .then(response => {
            this.$router.push({ name: 'autostrada-widgets-vacancy' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      } else {
        axios.post(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/vacancy`, data)
          .then(response => {
            this.$router.push({ name: 'autostrada-widgets-vacancy' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      if (this.$attrs.id) {
        axios.get(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/vacancy/${this.$attrs.id}`)
          .then(response => {
            console.log(response.data)
            this.label = response.data.label
            this.city = response.data.city
            this.published = !!response.data.published
            this.link = response.data.link
            const date = new Date(response.data.published_at * 1000)
            this.published_at = date.toISOString().substr(0, 16).replace('T', ' ')
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
          })
      }
    },
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
